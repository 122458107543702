export default {
    data() {
        return {
            url_img: '',

        }
    },
    beforeMount() {
        this.url_img = this.$url_img
    },
    methods: {

    }
}