import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)


export default new VueRouter({
    routes: [{
            path: '/',
            meta: { title: '首页' },
            component: () =>
                import ("../components/HelloWorld")
        },
        {
            path: '/course',
            meta: { title: '课程' },
            component: () =>
                import ("../components/coursLst")
        },
        {
            path: '/couDetails',
            meta: { title: '课程详情' },
            component: () =>
                import ("../components/courseDetails")
        },
        {
            path: '/coursePay',
            meta: { title: '课程详情' },
            component: () =>
                import ("../components/coursePay")
        },
        {
            path: '/user',
            meta: { title: '用户中心' },
            component: () =>
                import ("../components/userInfo")
        },
        {
            path: '/certificate',
            meta: { title: '资质证件' },
            component: () =>
                import ("../components/certiFicate")
        }
    ]
})