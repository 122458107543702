import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { domain, request } from "@/utils/request";
import VueRouter from "vue-router"

import mixin from "./utils/new_file.js"
Vue.mixin(mixin)

Vue.prototype.$url_img = domain
Vue.prototype.$http = request

Vue.use(VueRouter)
Vue.use(ElementUI);


Vue.prototype.$event = new Vue();


Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')